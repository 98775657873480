@import 'common-flagship_tasting_room';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn.m-alt {
	border: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: var(--button_alt-background_color, $color-button_alt-background);
			color: var(--button_alt-text_color, $color-button_alt-text);
		}
	}
}

.b-dialog-btn.m-alt .b-dialog-btn_text {
	@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
	@include t-link_underlined($state: hover);

	letter-spacing: inherit;
}



h1,h3{
	
	color: var(--mobile-color, $color-barefoot-purple);
	@include media(md-up) {
		color: var(--color, $color-barefoot-purple);
		
	}
}
h2{
	color: var(--mobile-color, $color-purple-accent);
	@include media(md-up) {
		color: var(--color, $color-purple-accent);
		
	}
}

.b-login_panel-title{
	color: $color-purple-accent;
}
.b-shipping_panel-title{
	color: $color-purple-accent;
}
.b-categories_navigation-link_1{
	color: $color-barefoot-purple;
}
.b-categories_navigation-list_2 {
    color: $color-mauve-accent;
}

.b-ship_to-state{
 	border-bottom: 1px solid $color-mauve-accent;
	 color: $color-mauve-accent;
}


.b-breadcrumbs {
    &-item {
		align-items: baseline;
		&:not(.m-current) {
			@include media(md-up) {
				@include icon('arrow-right', 8px, 8px, after) {
					background: $color-breadcrumb-separator_bg;
					margin: 2px 15px 0;
				}
			}
		}
	}
}

.b-product_tile {
	&.m-grid{
		.b-product_quantity {
			border-right: 2px solid $color-quantity;
			margin-bottom: 8px;
			&.m-disabled {
				border-color: $color-shade_3;
			}
			@include media(md-up) {
				margin-bottom: 0px;
				margin-right: 8px;
				border-right: 2px solid $color-quantity;
			}
		}
	}
}

.b-carousel {
	.b-product_tile-actions {
		display: flex;
		@include media(sm) {	
		display: block;
		}
	}
}


.b-product_tile-name{
  	color:#5B5287; 
}

.b-product_price-value{
	color:$color-barefoot-purple;
}

.f-input_radio-field{
	background-color: $color-mauve-accent;
}

.f-input_checkbox-field{
	background-color: $color-mauve-accent;
}

.b-footer{
	border-top:1px solid $color-footer-border;
}

.b-footer_navigation{
	&-title{
	color:#5B5287;
	text-transform: capitalize;
	}
	&-content{
		color: #805080;
	}
	&-link{
		color: #805080 !important;
	}
}
.b-footer_info-title{
	color: #5B5287;
}
.b-footer_social-title{
	color: #5B5287;
}
.b-footer-newsletter_title{
	color: #5B5287;
}
.b-footer-copyright{
	color:#75717C;
	opacity:1;
}

.b-footer_legal-link{
	color:#805080;
}

.b-product_quantity{
	border-radius: $global-radius;
}

.b-categories_navigation { 
	&-item_1:after {
		border-bottom: 3px solid $color-pink-accent;    	
	}
}

.b-tab_list-tab.m-active{
	border-bottom: 3px solid $color-pink-accent;
}

.b-product_badge {

	&.m-promo {
		background: $color-purple-accent;
		border-radius: $global-radius;
	}
	color: $color-purple-accent;
	&-image {
		background: none !important;
		border: none;
	}
	&.m-rating_points {
		border: 2px solid $color-purple-accent;
		border-radius: 50%;
	}

	&-abbr, &-text {
		font-size: 16px !important;
		letter-spacing: 0.15px;
		line-height: 24px;

		@include media(md-down) {
			font-size: 12px !important;
			letter-spacing: 0.12px;
			line-height: 14px;
		}
	}

	&-points {
		font-size: 20px !important;
		letter-spacing: 0.31px;

		@include media(md-down) {
			font-size: 16px !important;
			letter-spacing: 0.19px;
		}
	}
}

.b-footer-newsletter_title{
	font: $configuration-t_heading_6-font;
	letter-spacing: 0.3px;
}

.b-carousel {
	&-control{
		border: 0;
	}
	&-pagination_control{
		border: 1px solid $color-mauve-accent;
	}
}

.b-footer-need_help .content-asset {
	display: none;
}
.b-footer-email_subscription {
	padding-bottom: 0;
}
.b-footer_social-links {
	@include media(sm) {
		column-gap: 0 !important;
		a {
			margin-left: 16px;
		}
	}
}

.b-footer{
	@include media(sm){
		padding-bottom: 120px;
	}
}

// Agegate
.m-age_gate-open {
	.b-age_gate {
		background-image: none;
		background-color: $color-input-border;

		.b-footer_legal-link, .b-footer-copyright {
			color: $color-primary-background;
		}
	}
	.b-age_gate-header {
		height: 107px;
		width: 107px;
		margin: 0 auto -54px;
		position: relative;
		z-index: 1;
		@include media(md-up) { 
			    margin-top: 140px;
		}
	}

	.b-age_gate-inner{
		padding: 17px 0 0;
		@include media(md-up) { 
			display: flex;
			flex-direction: column;
			padding-top: 0;
		}
	}

	.b-age_gate-main {
		background-color: $color-primary-background;
		border-radius: 12px;
		grid-column-start: header-s;
		grid-column-end: header-e;
		margin: -54px auto 0;
		display: grid;
		margin: 0 auto;
		padding: 70px 20px 30px;
		width: calc(100% - 2rem);
		@include media(md-up) { 
			display: flex;
			flex-direction: column;
			justify-self: center;

			padding: 62px 58px 40px;
			width: 674px;
		}
	}

	.b-age_gate-title {
		font-family: $font-main;
		font-size: 34px;
		font-weight: 900;
		line-height: 38px;
		margin-bottom: 20px;
		order:0;

		@include media(md-up) { 
			font-size: 40px;
			line-height: 46px;
		}
	}

	.b-age_gate-subtitle .t-paragraph_3, .too-young {
		font-size: 18px;
		line-height: 24px;
		@include media(md-up) { 
			font-size: 22px;
			line-height: 27px;
		}
	}

	.b-age_gate-subtitle { 
		order: 1;
	}

	.b-age_gate-form, .too-young {
		order: 2;
	}
	.b-age_gate-form {
		display: grid;
		grid-column-gap: 14px;
		margin-bottom: 35px;
		grid-template-columns: repeat(3, 108px);

		@include media(sm) {
			grid-template-columns: repeat(2, 80px) 108px;
		}
		.age-gate-form-field{
			width: 100%;
		}
		.age-gate-input {
			border: 2px solid $color-primary;
			width: 100%;
			border-radius: 3px;
			&:placeholder-shown {
				border-color: rgba(34, 56, 58, 0.5);
				color: rgba(34, 56, 58, 0.5);
				margin-right: 0;

				&:focus {
					border-color: $color-primary;
					color: $color-primary;
				}
			}
		}

		.b-age_gate-submit {
			background-color: $color-darkpink-accent;
			border-color: rgba(255, 255, 255, 0);
			color: $color-primary-background;
			cursor: pointer;
			font-size: 18px;
			grid-column: 1/span 3;
			justify-self: center;
			margin-top: 30px;
			min-width: auto;
			width: 100%;
			border-radius: 5px;
			@include media(md-up) { 
				max-width: 152px;
			}
		}
	}
	.b-age_gate-description {
		margin-bottom: 0;
		order: 3;
		padding: 20px;
	}

	.b-age_gate-footer {
		align-self: end;
		grid-area: footer-s/footer-s/footer-e/footer-e;
		margin-bottom: 20px;
		@include media(md-up) { 
			display: flex;
			flex-grow: 1;
			align-items: flex-end;
			justify-content: center;
			width: 100%;
		}

		.b-footer-bottom_info {
			@include media(md-up) { 
				width: 100%;
				padding: 30px 60px;
			}
		}
	}
}

.b-ship_to-link, .b-header-navigation_link.m-stores{
	color: $color-barefoot-purple;
}